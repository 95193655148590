import { useEffect } from 'react';

const useBaiduAnalytics = () => {
  useEffect(() => {
    // 百度统计代码
    window._hmt = window._hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?6bb2e80ad57e829e4055828f7ae03610";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();

    // 监听路由变化
    const handleRouteChange = () => {
      if (window._hmt) {
        window._hmt.push(['_trackPageview', window.location.pathname + window.location.search]);
      }
    };

    // 使用 history API 来监听路由变化
    window.addEventListener('popstate', handleRouteChange);

    // 重写 pushState 和 replaceState 方法来捕获路由变化
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function() {
      originalPushState.apply(this, arguments);
      handleRouteChange();
    };

    window.history.replaceState = function() {
      originalReplaceState.apply(this, arguments);
      handleRouteChange();
    };

    // 清理函数
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);
};

export default useBaiduAnalytics;