import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { marked } from 'marked';
import DOMPurify from 'dompurify';  // 如果您还没有安装,请运行: yarn add dompurify

const CommentWrapper = styled.div`
  max-width: 960px;
  margin: 40px auto;
  padding: 0 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  border: 1px solid ${props => props.theme.lineColor};
  // box-shadow: 0 2px 2px;
  @media (max-width: 768px) {
    border: none;
    padding: 0;
  }
`;

const CommentHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 5px;
  color: var(--primary-color);
`;

const CommentSubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  color: var(--text-color);
  border-bottom: 1px solid ${props => props.theme.lineColor};
`;

const CommentListWrapper = styled.div`
  max-height: ${props => props.maxHeight}px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease-out;
`;

const CommentList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const CommentItem = styled.li`
  margin-bottom: 12px;
  list-style-type: none;
`;

const CommentContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

const CommentBody = styled.div`
  flex: 1;
`;

const CommentAuthor = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 4px;
`;

const CommentText = styled.div`
  margin: 0;
  color: var(--text-color);
`;

const CommentDate = styled.span`
  font-size: 12px;
  color: var(--text-color);
  margin-left: 8px;
`;

const ChildComments = styled.ul`
  margin-left: 44px;
  margin-top: 12px;
  padding: 0;
  list-style-type: none;
`;

const Overlay = styled.div`
  display: ${props => props.showOverlay ? 'block' : 'none'};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), var(--background-color));
`;

const ExpandButton = styled.button`
  display: ${props => props.showButton ? 'block' : 'none'};
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    opacity: 0.9;
  }
`;

const CommentComponent = ({ comment }) => {
  const parsedContent = useMemo(() => {
    const rawMarkup = marked(comment.content);
    return DOMPurify.sanitize(rawMarkup);
  }, [comment.content]);

  return (
    <CommentItem>
      <CommentContent>
        <Avatar src={comment.headImg} alt={comment.nickName} />
        <CommentBody>
          <CommentAuthor>
            {comment.nickName}
            <CommentDate>{comment.gmtCreated}</CommentDate>
          </CommentAuthor>
          <CommentText dangerouslySetInnerHTML={{ __html: parsedContent }} />
        </CommentBody>
      </CommentContent>
      {comment.children && comment.children.length > 0 && (
        <ChildComments>
          {comment.children.map(child => (
            <CommentComponent key={child.id} comment={child} />
          ))}
        </ChildComments>
      )}
    </CommentItem>
  );
};

// 辅助函数：递归计算评论总数
const countTotalComments = (comments) => {
  return comments.reduce((total, comment) => {
    return total + 1 + (comment.children ? countTotalComments(comment.children) : 0);
  }, 0);
};

const CommentSystem = ({ comments }) => {
  console.log('CommentSystem received comments:', comments);
  const [maxHeight, setMaxHeight] = useState(300);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const commentListRef = useRef(null);

  const totalCommentCount = useMemo(() => countTotalComments(comments), [comments]);

  // 新增：对顶层评论进行排序
  const sortedComments = useMemo(() => {
    return [...comments].sort((a, b) => {
      return new Date(b.gmtCreated) - new Date(a.gmtCreated);
    });
  }, [comments]);

  const checkContentHeight = () => {
    if (commentListRef.current) {
      const contentHeight = commentListRef.current.scrollHeight;
      setShowExpandButton(contentHeight > maxHeight);
    }
  };

  useEffect(() => {
    checkContentHeight();
  }, [comments, maxHeight]);

  const expandComments = () => {
    const newMaxHeight = maxHeight + 1000;
    setMaxHeight(newMaxHeight);
  };

  return (
    <CommentWrapper id="comment">
      <CommentHeader>历史评论 ({totalCommentCount})</CommentHeader>
      <CommentSubTitle>Tips: 由于评论系统已经采用Github Issue，所以原来的评论功能不再可用，这里仅仅是备份显示原来的数据</CommentSubTitle>
      <CommentListWrapper maxHeight={maxHeight}>
        <CommentList className="comment-list" ref={commentListRef}>
          {sortedComments.map(comment => (
            <CommentComponent key={comment.id} comment={comment} />
          ))}
        </CommentList>
        <Overlay showOverlay={showExpandButton} />
        <ExpandButton 
          showButton={showExpandButton} 
          onClick={expandComments}
        >
          展开更多历史评论
        </ExpandButton>
      </CommentListWrapper>
    </CommentWrapper>
  );
};

export default CommentSystem;