import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { marked } from 'marked';
import hljs from 'highlight.js';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Layout, Typography, Button } from 'antd';
import { HomeFilled, FontSizeOutlined, MoonFilled, SunOutlined, CloseOutlined, MenuOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTheme } from '../ThemeContext';
import TableOfContent from '../components/TableOfContent';
import CommentSystem from '../components/CommentSystem';
import Beaudar from '../components/Beaudar';
import { lightTheme, darkTheme } from '../theme';
import Cookies from 'js-cookie';

const { Content } = Layout;
const { Title } = Typography;

const PageWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const StyledContent = styled(Content)`
  max-width: 960px;
  margin: 0 auto;
  padding: 0;
  transition: transform 0.3s ease;
  transform: translateX(${props => props.sidebarVisible ? '200px' : '0'});

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 24px;
`;

const HeaderContent = styled.div`
  text-align: center;
`;

const StyledTitle = styled(Title)`
  &.ant-typography {
    margin-bottom: 16px;
    color: ${props => props.theme.primaryColor};
    font-size: 1.75rem;
    // padding-bottom: 8px;
    // border-bottom: 1px solid ${props => props.theme.lineColor};
  }
`;

const CreationTime = styled.p`
  color: var(--secondary-color);
  font-size: 1rem;
  margin-bottom: 24px;
`;

const HomeLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.2rem;
  color: var(--primary-color);
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    opacity: 0.8;
  }
`;

const HomeLinkWrapper = styled.div`
  text-align: left;
`;

const StyledHomeIcon = styled(HomeFilled)`
  font-size: 16px;
  margin-right: 5px;
  color: var(--primary-color);
`;

const HomeText = styled.span`
  color: var(--primary-color);
`;

const SidebarToggle = styled(MenuOutlined)`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
  opacity: ${props => props.sidebarVisible || !props.showToggle ? 0 : 1};
  visibility: ${props => props.sidebarVisible || !props.showToggle ? 'hidden' : 'visible'};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: ${props => props.theme.isDark ? '#2d343b' : '#f0f2f5'};
  transform: translateX(${props => props.visible ? '0' : '-200px'});
  transition: transform 0.3s ease;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const SidebarHeader = styled.div`
  padding: 20px;
  position: relative;
`;

const SidebarContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 0 20px 0;
  width: 100%;
`;

const Settings = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const SettingsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#eee'};
  box-sizing: content-box;
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

const SettingsItem = styled.li`
  cursor: pointer;
  font-size: 14px;
  width: 50%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#eee'};
  color: ${props => props.theme.isDark ? '#ffffff' : '#000000'};
  transition: background-color 0.3s ease;
  margin-bottom: 0;

  &:nth-child(odd) {
    border-right: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#eee'};
  }

  &:hover {
    background-color: ${props => props.theme.isDark ? '#3c3c3c' : '#eee'};
    color: var(--primary-color);
  }

  .anticon {
    font-size: 16px;
  }
`;

const SidebarTitle = styled.h3`
  margin-bottom: 10px;
  color: ${props => props.theme.isDark ? '#ffffff' : 'rgba(0, 0, 0, 0.65)'};
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
`;

const StyledTableOfContent = styled(TableOfContent)`
  padding-left: 20px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
  
  &:hover {
    color: ${props => props.theme.primaryColor};
  }
`;


const ArticleContent = styled.div`
  h1, h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;

const Footer = styled.footer`
  text-align: center;
  margin-top: 40px;
  padding: 20px 0 10px;
  border-top: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#eaecef'};
  color: var(--secondary-color);
  font-size: 14px;
`;

// 全局配置
const API_BASE_URL = 'https://javadoop-api.hongjie-v.workers.dev';

const PostHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.lineColor};
  margin-bottom: 12px;
`;

const TitlePlaceholder = styled.div`
  width: 24px; // 调整这个值以匹配下载图标的宽度
`;

const IconDownload = styled(Link)`
  color: ${props => props.theme.primaryColor};
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  &:hover {
    opacity: 0.8;
  }
`;

const CommentSectionDivider = styled.hr`
  margin: 40px 0;
  border: 0;
  border-top: 1px solid ${props => props.theme.lineColor};
`;

function Post() {
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [toc, setToc] = useState([]);
  const { id } = useParams();
  const [fontSize, setFontSize] = useState(() => {
    return parseInt(Cookies.get('fontSize') || '16', 10);
  });
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { isDark, toggleTheme } = useTheme();
  const contentRef = useRef(null);
  const [authorInfo, setAuthorInfo] = useState('HongJie');
  const [showToggle, setShowToggle] = useState(window.innerWidth > 768);

  const handleDoubleClick = () => {
    setAuthorInfo(authorInfo === 'HongJie' ? 'HongJie (hongjie.v@gmail.com)' : 'HongJie');
  };

  useEffect(() => {
    // 从API获取文章内容
    const fetchPost = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/post/get-post-content?name=${id}`);
        if (!response.ok) {
          throw new Error('网络响应不正常');
        }
        const postData = await response.json();
        if (postData && postData.title && postData.date && postData.content) {
          setPost(postData);
          // 设置网页标题
          document.title = postData.title;
        } else {
          console.error('API 响应中缺少必要的字段');
          setPost(null);
        }
        
      } catch (error) {
        console.error('获取文章内容时出错:', error);
        setPost(null);
      }
    };

    // 从API获取评论数据
    const fetchComments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/comment/get-comment?name=${id}`);
        if (!response.ok) {
          throw new Error('网络响应不正常');
        }
        const responseData = await response.json();
        if (responseData.data) {
          setComments(responseData.data);
        } else {
          console.error('API 响应中没有 data 字段');
          setComments([]);
        }
      } catch (error) {
        console.error('获取评论数据时出错:', error);
        setComments([]);
      }
    };

    fetchPost();
    fetchComments();
  }, [id]);

  useEffect(() => {
    if (post && post.content) {
      const headings = post.content.match(/^#{1,6} .+$/gm) || [];
      const tocItems = headings.map((heading, index) => ({
        level: heading.match(/^#{1,6}/)[0].length,
        text: heading.replace(/^#{1,6} /, ''),
        href: `#toc_${index + 1}`
      }));
      setToc(tocItems);
    }
  }, [post]);

  useEffect(() => {
    // 设置自定义渲染器
    marked.use({
      renderer: {
        heading(text, level) {
          this.tocIndex = this.tocIndex || 0;
          this.tocIndex++;
          return `
            <div id="toc_${this.tocIndex}"></div>
            <h${level}>
              <a name="toc_${this.tocIndex}" class="anchor" href="#toc_${this.tocIndex}">
                <span class="header-link"></span>
              </a>
              ${text}
            </h${level}>`;
        }
      }
    });
  }, []);

  useEffect(() => {
    // 设置自定义渲染器
    marked.use({
      renderer: {
        code(code, language) {
          const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
          return `<pre><code class="hljs ${validLanguage}">${
            hljs.highlight(code, { language: validLanguage }).value
          }</code></pre>`;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const codeBlocks = contentRef.current.querySelectorAll('pre code');
      codeBlocks.forEach((block) => {
        hljs.highlightElement(block);
      });
    }
  }, [post]);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => {
      const newSize = Math.max(prevSize - 1, 12);
      Cookies.set('fontSize', newSize.toString(), { expires: 30 });
      return newSize;
    });
  };

  const increaseFontSize = () => {
    setFontSize(prevSize => {
      const newSize = Math.min(prevSize + 1, 24);
      Cookies.set('fontSize', newSize.toString(), { expires: 30 });
      return newSize;
    });
  };

  const handleThemeChange = (theme) => {
    if (theme === 'dark' && !isDark) {
      toggleTheme();
      Cookies.set('theme', 'dark', { expires: 30 });
    } else if (theme === 'light' && isDark) {
      toggleTheme();
      Cookies.set('theme', 'light', { expires: 30 });
    }
  };

  const renderContent = () => {
    if (!post) return null;
    const html = marked.parse(post.content);
    return (
      <ArticleContent 
        id="content" 
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: html }} 
        theme={{ isDark }}
      />
    );
  };

  const handleDownload = () => {
    window.open(`${API_BASE_URL}/post/download?name=${id}`, '_blank');
  };

  useEffect(() => {
    const savedFontSize = Cookies.get('fontSize');
    if (savedFontSize) {
      setFontSize(parseInt(savedFontSize, 10));
    }
    const savedTheme = Cookies.get('theme');
    if (savedTheme) {
      if ((savedTheme === 'dark' && !isDark) || (savedTheme === 'light' && isDark)) {
        toggleTheme();
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        setShowToggle(window.scrollY > 200);
      } else {
        setShowToggle(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  if (!post) return <div>加载中...</div>;

  return (
    <PageWrapper>
      <SidebarToggle
        onClick={toggleSidebar}
        sidebarVisible={sidebarVisible}
        showToggle={showToggle}
      />
      <Sidebar visible={sidebarVisible} theme={{ isDark }}>
        <SidebarHeader>
          <CloseButton
            icon={<CloseOutlined />}
            onClick={() => setSidebarVisible(false)}
          />
        </SidebarHeader>
        <SidebarContent>
          <Settings>
            <SettingsList theme={{ isDark }}>
              <SettingsItem theme={{ isDark }} onClick={decreaseFontSize}>
                <FontSizeOutlined /> A-
              </SettingsItem>
              <SettingsItem theme={{ isDark }} onClick={increaseFontSize}>
                <FontSizeOutlined /> A+
              </SettingsItem>
              <SettingsItem theme={{ isDark }} onClick={() => handleThemeChange('dark')}>
                <MoonFilled />
              </SettingsItem>
              <SettingsItem theme={{ isDark }} onClick={() => handleThemeChange('light')}>
                <SunOutlined />
              </SettingsItem>
            </SettingsList>
          </Settings>
          <SidebarTitle theme={{ isDark }}>文章导航</SidebarTitle>
          <StyledTableOfContent toc={toc} />
        </SidebarContent>
      </Sidebar>
      <StyledContent
        sidebarVisible={sidebarVisible}
        style={{ fontSize: `${fontSize}px` }}
      >
        <StyledHeader>
          <HomeLinkWrapper>
            <HomeLink to="/">
              <StyledHomeIcon /><HomeText>回首页</HomeText>
            </HomeLink>
          </HomeLinkWrapper>
          <HeaderContent>
            <PostHeaderTitle>
              <TitlePlaceholder />
              <StyledTitle level={1}>{post.title}</StyledTitle>
              <IconDownload to={`${API_BASE_URL}/post/download?name=${id}`} target="_blank" rel="noopener noreferrer">
                <DownloadOutlined />
              </IconDownload>
            </PostHeaderTitle>
            <CreationTime>更新时间: {post.date}</CreationTime>
          </HeaderContent>
        </StyledHeader>
        {renderContent()}
        {/* <CommentSectionDivider /> */}
        <CommentSystem comments={comments} />
        {/* <CommentSectionDivider /> */}
        <Beaudar title={post.title} />
        <Footer>© Javadoop {new Date().getFullYear()}, Created by{' '}
          <span onDoubleClick={handleDoubleClick}>
            {authorInfo}
          </span>
        </Footer>
      </StyledContent>
    </PageWrapper>
  );
}

export default Post;
