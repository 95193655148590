import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --background-color: ${props => props.theme.backgroundColor};
    --text-color: ${props => props.theme.textColor};
    --primary-color: ${props => props.theme.primaryColor};
    --secondary-color: ${props => props.theme.secondaryColor};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    height: 100%;
  }

  body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif, Arial;
    font-size: 1rem;
    line-height: 1.8;
    transition: background-color 0.3s ease, color 0.3s ease, left 250ms ease;
  }

  p {
    color: ${props => props.theme.textColor};
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  .ant-comment-content {
    font-size: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 1px solid #ccc;
  }

  ol, ul {
    padding-left: 18px;
    overflow-x: auto;
    margin-bottom: 0.75rem;

    li {
      margin-bottom: 0.5rem;
      list-style: disc;

      ul {
        margin-top: 0.5rem;
      }
    }
  }

  // theme 适配
  blockquote {
    font-size: 1.0rem;
    padding: 0 10px;
    border-left: 4px solid ${props => props.theme.primaryColor};
    background-color: ${props => props.theme.blockquoteBackgroundColor};
    margin-bottom: 1em;
  }

  pre {
    border: ${props => props.theme.hljsBorder};
    border-radius: 4px;
    padding: 1rem;
    font-size: 0.9rem;
    line-height: 1.8;
    background-color: ${props => props.theme.hljsBackgroundColor};
    overflow-x: auto;

    code::-webkit-scrollbar {
      height: 6px;
      background-color: #F5F5F5;
    }

    code::-webkit-scrollbar-thumb {
      border-radius: 6px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #888;
    }

    code::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 2px;
      background-color: #bfbcbc;
    }

    code {
      font-family: Courier, Monaco, Consolas, "Liberation Mono", monospace;
      overflow-x: auto;
      white-space: pre;
      -ms-word-wrap: normal;
      word-wrap: normal;

      .hljs {
        background-color: ${props => props.theme.hljsBackgroundColor};
        border: none;
        border-radius: 3px;
      }

      .hljs-comment {
          color: ${props => props.theme.hljsCommentColor};
      }

      .hljs-keyword {
          color: ${props => props.theme.hljsKeywordColor};
          font-weight: normal;
      }

      .hljs-title {
          font-weight: 500;
          color: ${props => props.theme.hljsTitleColor};
      }

      .hljs-name {
          color: ${props => props.theme.hljsNameColor};
      }

      .hljs-attr {
          color: ${props => props.theme.hljsAttrColor};
      }

      .hljs-meta {
          color: ${props => props.theme.hljsMetaColor};
      }
    }
  }

  // p {
  //     white-space: pre-wrap;
  //     word-break: break-word;
  // }

  // p code,
  // ul code,
  // ol code {
  //     color: #ab5683;
  //     background-color: #f1f3f7;
  //     border-radius: 0.25rem;
  //     white-space: pre-wrap;
  //     word-break: break-word;
  // }

  hr {
    border: 1px solid ${props => props.theme.lineColor};
    margin-bottom: 1rem;
  }
  ol,
  ul {
      padding-left: 18px;
  }
  p a,
  ul a,
  ol a {
      color: ${props => props.theme.secondaryColor};
      text-decoration: none;
      transition: color 0.3s ease;
      &:hover {
          color: ${props => props.theme.linkHoverColor};
      }
  }


  p code, ul code, ol code {
    color: ${props => props.theme.inlineCodeColor};
    background-color: ${props => props.theme.inlineCodeBackgroundColor};
    border-radius: 0.25rem;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 0.2em 0.4em;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;

    th, td {
      border: 1px solid ${props => props.theme.isDark ? '#4a4a4a' : '#c5c5c5'};
      padding: 0.5em;
    }

    th {
      background-color: ${props => props.theme.isDark ? '#3a3a3a' : '#f6f8fa'};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.contentHeaderColor};
    margin-bottom: 0.75rem;
    margin-top: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
  }
  ::selection {
    background: rgba(164, 205, 253, 0.7);
    color: #000;
  }

  ::-moz-selection {
    background: rgba(164, 205, 253, 0.7);
    color: #000;
}
`;

export default GlobalStyle;

